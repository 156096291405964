/* News.css */

.container {
    margin-top: 20px;
  }
  
  h1 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 30px;
    color: #333;
    animation: fadeInUp 1s ease;
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .news-card {
    margin-bottom: 30px;
    animation: fadeIn 1s ease;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .card {
    border: none;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    background-color: #fff;
  }
  
  .card:hover {
    transform: translateY(-5px);
  }
  
  .card-img-top {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .card-body {
    padding: 20px;
  }
  
  .card-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
  }
  
  .card-text {
    font-size: 1.2rem;
    color: #555;
  }
  
  .btn-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    animation: fadeIn 1s ease;
  }
  
  .btn {
    font-size: 1.2rem;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 0 10px;
    transition: background-color 0.3s ease;
    background-color: #007bff; /* professional blue color */
    color: #fff;
  }
  
  .btn:hover {
    background-color: #0056b3; /* darker shade of blue */
  }
  