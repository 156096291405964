/* NewsItem.css */

.news-card {
    margin-bottom: 30px;
  }
  
  .card {
    border: none;
    border-radius: 15px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    transition: transform 0.3s ease-in-out;
  }
  
  .card:hover {
    transform: translateY(-5px);
  }
  
  .card-img-top {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    height: 200px;
    object-fit: cover;
  }
  
  .card-body {
    padding: 20px;
  }
  
  .card-title {
    font-size: 1.6rem;
    font-weight: bold;
    color: #333333;
    margin-bottom: 10px;
  }
  
  .card-text {
    font-size: 1.2rem;
    color: #555555;
  }
  
  .btn {
    font-size: 1.2rem;
    border-radius: 5px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    transition: background-color 0.3s ease;
    text-decoration: none;
  }
  
  .btn:hover {
    background-color: #0056b3;
  }
  